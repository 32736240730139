import i18n from 'i18next';

export const CLEAR_SCAN_ERROR = 'CLEAR_SCAN_ERROR';
export const CLEAR_FINISHED_PROCESSING = 'CLEAR_FINISHED_PROCESSING';
export const SETUP_CANCELLATION_TOKEN = 'SETUP_CANCELLATION_TOKEN';

export function isSelectedScannerCaptureOne(getState) {
  return (
    getState().settings.userPreferedScannerCode === CAPTURE_ONE_SCANNER_CODE ||
    getState().settings.userPreferedScannerCode === CAPTURE_ONE_SCANNER_CODE_S1000II
  );
}
export function isSelectedScannerSimulateMode(getState) {
  return getState().settings.userPreferedScannerCode === SIMULATION_MODE;
}

export function clearScanError() {
  return dispatch => {
    dispatch({ type: CLEAR_SCAN_ERROR });
  };
}

export function clearProcessingFinished() {
  return { type: CLEAR_FINISHED_PROCESSING };
}

export function setupCancellationToken() {
  return { type: SETUP_CANCELLATION_TOKEN };
}

export * from './connectToScannerDriverActions';
export * from './getAndSelectScannerActions';
export * from './processScanItemsActions';
export * from './scanActions';
export * from './queueManagerActions';

const PANINI_VISION_X = 'X';
const PANINI_IDEAL = 'Y';

export const twainDriverErrorMessage = i18n.t('scanner.messages.deviceDriverNotFound');
export const twainDriverActionMessage = i18n.t('scanner.messages.deviceDriverNotFoundAction');
export const noDocumentsFoundErrorMessage = i18n.t('scanner.messages.noDocumentsFound');
export const noDocumentsFoundActionMessage = i18n.t('scanner.messages.noDocumentsFoundAction');

export const HANDLE_SCAN_ERROR = 'HANDLE_SCAN_ERROR';
export const CAPTURE_ONE_SCANNER_CODE = 'Z';
export const CAPTURE_ONE_SCANNER_CODE_S1000II = 'T';
export const SIMULATION_MODE = 'S';
export const FIVE_SECONDS = 5000;

export const scanningErrorTypes = {
  INIT: 'INIT',
  START: 'START',
  TWAIN_DRIVER: 'TWAIN_DRIVER',
  GET_TWAIN_SOURCES: 'GET_TWAIN_SOURCES',
  SELECT_TWAIN_SOURCE: 'SELECT_TWAIN_SOURCE',
  ACQUIRE: 'ACQUIRE',
  GET_STATUS: 'GET_STATUS',
  NO_ITEMS_SCANNER: 'NO_ITEMS_SCANNER',
  INVALID_BATCH_NUM: 'INVALID_BATCH_NUM',
  NO_SCANNER_SELECTED: 'NO_SCANNER_SELECTED',
  EVERNEXT: 'EVERNEXT',
  EVERNEXT_DRIVER: 'EVERNEXT_DRIVER'
};
